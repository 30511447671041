<template>
    <div class="page-table mainDiv" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.bultenler.kategori.title")}}

            <div class="tanimlamaButon">
                <el-tooltip :content='"Sayfa Güncelle"' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" type="text" icon="el-icon-refresh">
                    </el-button>
                </el-tooltip>
            </div>
        </div>
        <el-row :gutter="10">
            <el-col :lg="18" :md="18" :sm="24" :xs="24" v-loading="loading || stateLoading"
            :element-loading-text='loading ? $t("src.views.apps.bultenler.kategori.loading") : $t("src.views.apps.bultenler.kategori.stateLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
                <div class="page-header card-base card-shadow--medium animated fadeInUp">
                    <el-row class="mt-0 mb-10">
                        <el-col :lg="16" :md="16" :sm="24" :xs="24">
                            <div>
                                <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9" text-color="gray">
                                    <el-radio-button label="1">
                                        <i class="mdi mdi-page-next mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                        <transition name="slide-fade">
                                            <label v-if="selectIcon==='1'">{{$t("src.views.apps.bultenler.kategori.active")}}</label>
                                        </transition>
                                    </el-radio-button>
                                    <el-radio-button label="0">
                                        <i class="mdi mdi-page-next mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                        <transition name="slide-fade">
                                            <label v-if="selectIcon==='0'">{{$t("src.views.apps.bultenler.kategori.passive")}}</label>
                                        </transition>
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                        </el-col>

                        <el-col :lg="8" :md="8" :sm="24" :xs="24">
                            <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                <ul class="islemler">
                                    <li class="duzenle">
                                        <i class="mdi mdi-pen"></i> {{$t("src.views.apps.bultenler.kategori.edit")}}
                                    </li>
                                    <li v-if="selectIcon === '1'" class="sil">
                                        <i class="mdi mdi-trash-can"></i> {{$t("src.views.apps.bultenler.kategori.delete")}}
                                    </li>
                                    <li v-else class="aktif">
                                        <i class="mdi mdi-play"></i> {{$t("src.views.apps.bultenler.kategori.setActive")}}
                                    </li>
                                </ul>
                            </el-col>
                        </el-col>
                    </el-row>

                    <el-table border stripe :data="kategoriListe" style="width: 100%">
                        <el-table-column
                            :label='$t("src.views.apps.genel.sira")'
                            type="index"
                            width="50">
                        </el-table-column>
                        <el-table-column
                            prop="baslik"
                            :label='$t("src.views.apps.bultenler.kategori.table.header")'
                            width="auto">
                        </el-table-column>
                        <el-table-column
                            prop="link"
                            :label="'Link'"
                            width="450">
                        </el-table-column>
                        <el-table-column
                            :label='$t("src.views.apps.bultenler.kategori.table.menuState")'
                            width="auto">
                            <template slot-scope="scope">
                                <span v-if="scope.row.menuGoster === '1'">{{$t("src.views.apps.bultenler.kategori.table.show")}}</span>
                                <span v-if="scope.row.menuGoster == '0' || scope.row.menuGoster == ''">{{$t("src.views.apps.bultenler.kategori.table.hide")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                :label='$t("src.views.apps.genel.islem")'
                                align="right"
                                width="auto">
                            <template slot-scope="scope">
                                <el-tooltip :content='$t("src.views.apps.bultenler.kategori.edit")' :open-delay="500" placement="bottom">
                                    <el-button
                                        v-on:click="updateStart(scope.row); "
                                        type="text"
                                        sstyle="position: relative; top: 15px; margin-left: 15px !important;"
                                        icon="mdi mdi-pen mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip :content='$t("src.views.apps.bultenler.kategori.sil")' :open-delay="500" placement="bottom">
                                    <el-button
                                        v-if="selectIcon==='1'"
                                        v-on:click="kategoriDurumChange(scope.row, 0)"
                                        type="text"
                                        class="buttonDel"
                                        style="margin-left: 15px !important"
                                        icon="mdi mdi-trash-can mdi-24px">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip :content='$t("src.views.apps.sayfa.liste.yayindanKaldir")' :open-delay="500" placement="bottom">
                                    <el-button
                                        v-if="selectIcon==='0' "
                                        @click="kategoriDurumChange(scope.row, 1)"
                                        type="text"
                                        icon="mdi mdi-play mdi-24px">
                                    </el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>

                    <el-pagination
                        background
                        layout="sizes,prev, pager, next, jumper, total"
                        :total="total"
                        @size-change="handleSizeChange"
                        @current-change="pageChange"
                        :page-sizes="[10, 20, 50, 100]"
                        :current-page.sync="page"
                        :next-click.sync="page"
                        :prev-click.sync="page"
                        @next-click="pageChange"
                        @prev-click="pageChange">
                    </el-pagination>
                </div>
            </el-col>


            <el-col :lg="6" :md="6" :sm="24" :xs="24" v-loading="addLoading || updateLoading"
            :element-loading-text='addLoading ? $t("src.views.apps.bultenler.kategori.addLoading") : $t("src.views.apps.bultenler.kategori.updateLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
                <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp">
                    <el-form status-icon :model="kategoriForm" :rules="rulesKategori" ref="kategoriForm" class="demo-ruleForm">
                        <div>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.bultenler.tanimlama.baslik")' prop="baslik">:
                                        <el-input size="small" v-model="kategoriForm.baslik" :placeholder='$t("src.views.apps.bultenler.tanimlama.baslikPlace")'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.bultenler.kategori.menu")'>:
                                        <el-select size="small" v-model="kategoriForm.menu" @change="menuGosterChange" :placeholder='$t("src.views.apps.bultenler.kategori.menuPlace")' style="width: 100%">
                                            <el-option value="0" :label='$t("src.views.apps.bultenler.kategori.table.hide")'>{{$t("src.views.apps.bultenler.kategori.table.hide")}}</el-option>
                                            <el-option value="1" :label='$t("src.views.apps.bultenler.kategori.table.show")'>{{$t("src.views.apps.bultenler.kategori.table.show")}}</el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p" v-if="menuListVisible">
                                    <el-form-item :label='$t("src.views.apps.bultenler.kategori.menu")' prop="menuKategoriID">:
                                        <el-select size="small" v-model="kategoriForm.menuKategoriID" placeholder="Lütfen Menu Kategori Seçiniz" style="width: 100%">
                                            <el-option v-for="(item,index) in menuKategoriList" :key="index" :value="item.menuKategoriID" :label="item.baslik">{{item.baslik}}</el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24"  v-if="menuListVisible" class="col-p">
                                    <el-form-item class="margin-top-10">
                                        <el-button size="mini" type="success" v-on:click="iconListDialog = true" icon="el-icon-search">İcon Seç
                                        </el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p" v-if="menuListVisible">
                                    <el-form-item label='Menü Başlık' prop="menuBaslik">:
                                        <el-input size="small" v-model="kategoriForm.menuBaslik" placeholder='Lütfen Menü Başlık Giriniz'></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row class="col-p">
                            <el-form-item>
                                <el-button v-if="btnVisible" size="small" v-on:click="kategoriTanimlama('kategoriForm')" type="primary">
                                    {{$t('src.views.apps.bultenler.kategori.kategoriEkle')}}
                                </el-button>
                                <el-button v-else size="small" v-on:click="guncelleme('kategoriForm')" type="primary">
                                    {{$t('src.views.apps.bultenler.kategori.kategoriGuncelle')}}
                                </el-button>
                                <el-button v-if="!btnVisible" size="small" v-on:click="vazgec('kategoriForm')">
                                    Vazgeç
                                </el-button>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </div>
            </el-col>
        </el-row>


        <el-dialog :title='$t("src.views.apps.rakamlar.islem.iconList")' :visible.sync="iconListDialog" width="60%" center>
            <el-row class="iconRow">
                <el-input class="margin-bottom-10" placeholder="Listede Olmayan Bir Icon Girebilirsiniz." size="medium" v-model="kategoriForm.icon"></el-input>
                <el-col v-for="(item,index) in iconListe" :key="item" :label="item" :value="item" :lg="4" :md="4" :sm="8" :xs="8" style="border: 1px solid #9a9a9a; padding: 5px; height: 100px; text-align: center;display:table">
                    <div v-on:click="iconSelect(item)" class="iconCerceve" id="#iconCerceve">
                        <el-button type="text">
                            <i style="font-size: 24px;text-align: center" :class="item"></i>
                        </el-button>
                        <br>
                        <span class="icon-name" :data-class="item">{{item}}</span>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>

    </div>


</template>

<script>
    import bultenKategoriService from '../../../../WSProvider/BultenKategoriService'
    import MenuKategoriService from '../../../../WSProvider/MenuKategoriService'
    import bultenService from '../../../../WSProvider/BultenService'
    import notification from '../../../../notification'
    import menuKategoriService from '../../../../WSProvider/MenuKategoriService'
    import JQuery from "jquery";
    import iconJson from '../../../../icon'

    const $ = JQuery;

    export default {
        name: "KategoriTanimlama",
        mounted() {
            this.getKategoriCount();
            this.menuKategoriListe();
            this.getKategoriList();
        },
        data() {
            return {
                loading: false,
                stateLoading: false,
                addLoading: false,
                updateLoading: false,

                iconListe: iconJson["v5.0.9"],
                menuKategoriList: [],
                menuListVisible: false,
                iconListDialog: false,

                page: 0,
                sizes: 10,
                postPage: 0,
                total: null,

                selectIcon: '1',
                radio: '1',
                kategoriListe: [],
                path: bultenService.path,
                updateKodu: '',
                btnVisible: true,
                selection: '',
                kategoriForm: {
                    baslik: '',
                    link: '',
                    icon: '',
                    menuKategoriID: '',
                    menu: '0',
                    menuBaslik: '',
                },
                rulesKategori: {
                    baslik: [{
                        required: true,
                        message: "Lütfen Başlık Giriniz",
                        trigger: 'blur'
                    }],
                },
            }
        },
        methods: {
            refreshPage(){
                this.getKategoriList();
                this.getKategoriCount();
                this.menuKategoriListe();
                this.vazgec()
            },
            iconSelect(value) {
                this.kategoriForm.icon = value;
                $("body").find(".aktifIkon").removeClass("aktifIkon")
                $(".iconRow").find('span[data-class="' + value + '"]').closest(".iconCerceve").parent("div").addClass("aktifIkon");
                notification.Status("success", this, value + " iconu seçildi.")
            },
            menuGosterChange(val){
                if(val == '1') this.menuListVisible = true;
                else this.menuListVisible = false;
            },
            menuKategoriListe(){
                try{
                    menuKategoriService.menuKategoriListele().then((response) => {
                        if (response.status == 200) {
                            this.menuKategoriList = response.data;
                            localStorage.setItem("userDataDemirag", response.token)
                        }
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.menuKategoriList = [];
                            }
                            notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            notification.Status(503, this)
                        }
                    })
                }catch(e){
                    notification.Status("danger", this, "Menü kategori liste getirilemedi. İşlem sırasında bir hata meydana geldi.")
                }
            },
            handleChange(event) {
                this.selectIcon = event;
                this.postPage = 0;
                this.getKategoriList();
            },
            getKategoriList() {
                try{
                    this.loading = true;
                    bultenKategoriService.bKategoriListe(this.selectIcon, this.postPage, this.sizes).then((response) => {
                        if (response.status == 200) {
                            this.kategoriListe = response.data;
                            localStorage.setItem("userDataDemirag", response.token)
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.kategoriListe = [];
                            }
                            notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            notification.Status(503, this)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    this.loading = false;
                }
            },
            getKategoriCount() {
                try{
                    bultenKategoriService.bKategoriSayisi(this.selectIcon).then(response => {
                        if(response.status == 200){
                            localStorage.setItem("userDataDemirag", response.token);
                            this.total = response.data;
                        }
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){  
                                this.total = 0
                            }
                        }else{
                            notification.Status(503, this)
                        }
                    })
                }catch(e){
                }
            },

            handleSizeChange(val) {
                this.sizes = val;
                this.getKategoriList();
                this.getKategoriCount();
            },
            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.getKategoriList();
                this.getKategoriCount();
            },

            updateStart(scope) {
                this.updateKodu = scope.kategoriID;
                this.kategoriForm.baslik = scope.baslik;
                this.kategoriForm.menu = scope.menuGoster == '' ? '0' : scope.menuGoster;
                this.btnVisible = false;
                this.menuListVisible = scope.menuGoster == "1" ? true : false;
                this.kategoriForm.menuKategoriID = scope.menuGoster == "1" ? scope.menuKategoriID : "";
                this.kategoriForm.menuBaslik = scope.menuGoster == "1" ? scope.menuBaslik : "";
                this.kategoriForm.icon = scope.menuGoster == "1" ? scope.icon : "";
            },

            vazgec(formName) {
                this.$refs[formName].resetFields();
                this.kategoriForm.menu = "0";
                this.kategoriForm.icon = '';
                this.kategoriForm.menuKategoriID = '';
                this.kategoriForm.menuBaslik = '';
                this.menuListVisible = false;
                this.btnVisible = true
            },

            kategoriTanimlama(formName) {
                if(this.validateForm(formName)){
                    try{
                        this.addLoading = true;
                        //baslik, menuBaslik, menuIcon, menuGoster
                        bultenKategoriService.kategoriTanimla(this.kategoriForm.baslik, this.kategoriForm.menu == '1' ? this.kategoriForm.menuBaslik:'', this.kategoriForm.menu == '1' ? this.kategoriForm.icon:'', this.kategoriForm.menu, this.kategoriForm.menu == '1' ? this.kategoriForm.menuKategoriID:'').then((response) => {
                            if (response.status == 200) {
                                this.getKategoriList()
                                this.$refs[formName].resetFields();
                                this.kategoriForm.menu = "0";
                                this.kategoriForm.icon = '';
                                this.kategoriForm.menuKategoriID = '';
                                this.kategoriForm.menuBaslik = '';
                                this.menuListVisible = false;
                                this.getKategoriList(this.selectIcon);
                            }
                            notification.Status('success', this, response.msg);
                            this.addLoading = false;
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }else if(error.status == 404){
                                    this.kategoriListe = [];
                                }
                                notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                notification.Status(503, this)
                            }
                            this.addLoading = false;
                        })
                    }catch(e){
                        notification.Status("danger", this, "Kategori tanımla sırasında bir hata meydana geldi.")
                    }
                }
            },
            guncelleme(formName) {
                if(this.validateForm(formName)){
                    try{
                        this.updateLoading = true;
                        // menuBaslik, menuIcon, menuKategoriID
                        bultenKategoriService.kategoriGuncelle(this.updateKodu, this.kategoriForm.baslik, this.kategoriForm.menu, this.kategoriForm.menu == '1' ? this.kategoriForm.menuBaslik : "", this.kategoriForm.menu == '1' ? this.kategoriForm.icon : "", this.kategoriForm.menu == '1' ?  this.kategoriForm.menuKategoriID:'').then((response) => {
                            if (response.status === 200) {
                                this.getKategoriList();
                                this.getKategoriCount();
                                notification.Status('success', this, response.msg);
                                this.$refs[formName].resetFields();
                                this.btnVisible = true;
                                this.$refs[formName].resetFields();
                                this.kategoriForm.menu = "0";
                                this.kategoriForm.icon = '';
                                this.kategoriForm.menuKategoriID = '';
                                this.kategoriForm.menuBaslik = '';
                                this.menuListVisible = false;
                            }
                            this.updateLoading = false;
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                notification.Status(503, this)
                            }
                            this.updateLoading = false;
                        })
                    }catch(e){
                        this.updateLoading = false
                    }
                }
            },
            kategoriDurumChange(selection, durum) {
                var msg = durum == 0 ? 'Kategoriyi yayından kaldırmak istediğinize emin misiniz?' : 'Kategoriyi yayına almak istediğinize emini misiniz?'
                this.openConfirmDialog(msg).then((response) => {
                    try{
                        this.stateLoading = true;
                        bultenKategoriService.kategoriDurumGuncelle(selection.kategoriID, durum).then((response) => {
                            if (response.status === 200) {
                                this.getKategoriList();
                                this.getKategoriCount();
                            }
                            notification.Status('success', this, response.msg);
                            this.stateLoading = false;
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                notification.Status(503, this)
                            }
                            this.stateLoading = false;
                        })
                    }catch(e){
                        this.stateLoading = false
                    }
                })
            },
        }
    }

</script>


<style lang="scss" scoped>
    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    .el-button--mini {
        padding: 0px 15px !important;
        height: 25px !important;
    }
</style>
