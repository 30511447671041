import WsProvider from './provider/WSProvider'

var serviceName = "webservis";
var moduleName = "bulten";

var bultenService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,


    //'kategoriID','baslik','altBaslik','icerik','resim','video','resimKonum','menuKonum','galeriKonum','durum','baslamaTarihi','bitisTarihi','seoBaslik','seoIcerik','seoKategori','seoSorguTuru','seoDurum','seoSayac'
    bultenTanimlama(kategoriID, baslik, altBaslik, icerik, sunucudanEklenenResimler, resim, resimUzunluk, video, resimKonum, menuKonum, galeriKonum, durum, seoBaslik, seoIcerik, baslamaTarihi, bitisTarihi) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            kategoriID,
            baslik,
            altBaslik,
            icerik,
            sunucudanEklenenResimler,
            resim,
            resimUzunluk,
            video,
            resimKonum,
            menuKonum,
            galeriKonum,
            durum,
            seoBaslik,
            seoIcerik,
            baslamaTarihi,
            bitisTarihi,
            seoKategori: 1,
            seoSorguTuru: 1,
            seoDurum: durum,
            seoSayac: 0,
            serviceName: moduleName,
            methodName: "bultenEkle"
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    bultenListe(durum, baslangic, limit) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "bultenListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    bultenDurumSayisi(durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            durum,
            serviceName: moduleName,
            methodName: "bultenDurumSayisi"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    bultenCount() {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            serviceName: moduleName,
            methodName: "bultenSayisi"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    bultenDetay(bultenID, link) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            bultenID: bultenID,
            link: link,
            serviceName: moduleName,
            methodName: "bultenDetay"
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;

    },

    bultenDurumDegis(bultenID, durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            bultenID: bultenID,
            durum,
            serviceName: moduleName,
            methodName: "bultenAktifPasifEt"
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    bultenGuncelleme(bultenID, kategoriID, baslik, altBaslik, icerik, sunucudanEklenenResimler, resim, resimUzunluk, video, resimKonum, menuKonum, galeriKonum, baslamaTarihi, bitisTarihi, durum, seoBaslik, seoIcerik) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            bultenID: bultenID,
            kategoriID,
            baslik: baslik,
            altBaslik: altBaslik,
            icerik: icerik,
            sunucudanEklenenResimler: sunucudanEklenenResimler,
            resim: resim,
            resimUzunluk: resimUzunluk,
            video: video,
            resimKonum: resimKonum,
            menuKonum: menuKonum,
            galeriKonum: galeriKonum,
            baslamaTarihi: baslamaTarihi,
            bitisTarihi: bitisTarihi,
            seoKategori: 1,
            seoSorguTuru: 1,
            seoSayac: 0,
            durum: durum,
            seoBaslik,
            seoIcerik,
            methodName: "bultenGuncelle",
            serviceName: moduleName
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    bultenAktifPasifSayisi() {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            serviceName: moduleName,
            methodName: "bultenAktifPasifSayisi"
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },
}

export default bultenService