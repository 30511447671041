import WsProvider from './provider/WSProvider'

var serviceName = "webservis";
var moduleName = "bultenKategori";

var bultenService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    bKategoriListe(durum, baslangic, limit) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "bultenKategoriListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result;
    },

    bKategoriSayisi(durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            durum: durum,
            serviceName: moduleName,
            methodName: "bultenKategoriSayisi"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result;
    },

    kategoriDurumGuncelle(kategoriID, durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            durum: durum,
            kategoriID,
            serviceName: moduleName,
            methodName: "bultenAktifPasifEt"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result;
    },


    //$bultenKategoriEkle=['baslik','menuGoster','durum','seoBaslik','seoIcerik','seoKategori','seoSorguTuru','seoDurum','seoSayac'];
    kategoriTanimla(baslik, menuBaslik, menuIcon, menuGoster, menuKategoriID) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            baslik,
            menuBaslik,
            menuGoster,
            goster: menuGoster,
            durum: 1,
            seoBaslik: baslik,
            seoIcerik: baslik,
            ustMenuID: 0,
            menuKategoriID,
            menuIcon,
            menuSiraNo: 0,
            seoKategori: 1,
            seoSorguTuru: 1,
            seoDurum: 1,
            seoSayac: 0,
            serviceName: moduleName,
            methodName: "bultenKategoriEkle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result;
    },

    // $bultenKategoriGuncelle=['kategoriID','baslik','menuGoster','durum','goster','ustMenuID','menuKategoriID','menuBaslik','menuIcon','menuSiraNo'];
    kategoriGuncelle(kategoriID, baslik, menuGoster, menuBaslik, menuIcon, menuKategoriID) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            kategoriID,
            baslik,
            menuGoster,
            goster: menuGoster,
            menuBaslik,
            menuIcon,
            menuKategoriID,
            ustMenuID: 0,
            menuSiraNo: 0,
            durum: 1,
            serviceName: moduleName,
            methodName: "bultenKategoriGuncelle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result;
    },

}

export default bultenService